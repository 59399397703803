// extracted by mini-css-extract-plugin
export var authorLinkedIn = "HeroBannerMobile-module--authorLinkedIn--76557";
export var backgroundCircles = "HeroBannerMobile-module--backgroundCircles--7508c";
export var contactButton = "HeroBannerMobile-module--contactButton--68975";
export var contactUs = "HeroBannerMobile-module--contactUs--06ff4";
export var descriptionHeaderWrapper = "HeroBannerMobile-module--descriptionHeaderWrapper--e5c5d";
export var headerAndDescription = "HeroBannerMobile-module--headerAndDescription--ea29e";
export var heroBackgroundCircleLeft = "HeroBannerMobile-module--heroBackgroundCircleLeft--1da0a";
export var heroBackgroundCircleLeftMobile = "HeroBannerMobile-module--heroBackgroundCircleLeftMobile--98ae4";
export var heroBackgroundCircleRight = "HeroBannerMobile-module--heroBackgroundCircleRight--ab730";
export var heroBackgroundInnerCircleGreen = "HeroBannerMobile-module--heroBackgroundInnerCircleGreen--2def4";
export var heroBackgroundInnerCircleLeft = "HeroBannerMobile-module--heroBackgroundInnerCircleLeft--dafe9";
export var heroBackgroundInnerCircleLeftBlurBlue = "HeroBannerMobile-module--heroBackgroundInnerCircleLeftBlurBlue--6a4db";
export var heroWhiteBlurCirce = "HeroBannerMobile-module--heroWhiteBlurCirce--91830";
export var homeMobileWrapper = "HeroBannerMobile-module--homeMobileWrapper--f2b7d";
export var reportBtn = "HeroBannerMobile-module--reportBtn--9aee3";
export var reportBtnGerman = "HeroBannerMobile-module--reportBtnGerman--67fa8";